@charset "UTF-8";
/* Roobert Regular */
@font-face {
  font-family: 'Roobert';
  font-display: swap;
  src: url("./fonts/roobert/Roobert-Regular.woff2") format("woff2"), url("./fonts/roobert/Roobert-Regular.woff") format("woff"), url("./fonts/roobert/Roobert-Regular.eot") format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

/* Roobert Bold */
@font-face {
  font-family: 'RoobertBold';
  font-display: swap;
  src: url("./fonts/roobert/Roobert-Bold.woff2") format("woff2"), url("./fonts/roobert/Roobert-Bold.woff") format("woff"), url("./fonts/roobert/Roobert-Bold.eot") format("embedded-opentype");
  font-weight: 700;
  font-style: normal; }

/* Roobert Regular Italic */
@font-face {
  font-family: 'RoobertItalic';
  font-display: swap;
  src: url("./fonts/roobert/Roobert-RegularItalic.woff2") format("woff2"), url("./fonts/roobert/Roobert-RegularItalic.woff") format("woff"), url("./fonts/roobert/Roobert-RegularItalic.eot") format("embedded-opentype");
  font-weight: 400;
  font-style: italic; }

/* Roobert Bold Italic */
@font-face {
  font-family: 'RoobertBoldItalic';
  font-display: swap;
  src: url("./fonts/roobert/Roobert-BoldItalic.woff2") format("woff2"), url("./fonts/roobert/Roobert-BoldItalic.woff") format("woff"), url("./fonts/roobert/Roobert-BoldItalic.eot") format("embedded-opentype");
  font-weight: 700;
  font-style: italic; }

/* Roobert Semi Bold */
@font-face {
  font-family: 'RoobertSemiBold';
  font-display: swap;
  src: url("./fonts/roobert/Roobert-SemiBold.woff2") format("woff2"), url("./fonts/roobert/Roobert-SemiBold.woff") format("woff"), url("./fonts/roobert/Roobert-SemiBold.eot") format("embedded-opentype");
  font-weight: 600;
  font-style: normal; }

/* Roobert Semi Bold Italic */
@font-face {
  font-family: 'RoobertSemiBoldItalic';
  font-display: swap;
  src: url("./fonts/roobert/Roobert-SemiBoldItalic.woff2") format("woff2"), url("./fonts/roobert/Roobert-SemiBoldItalic.woff") format("woff"), url("./fonts/roobert/Roobert-SemiBoldItalic.eot") format("embedded-opentype");
  font-weight: 600;
  font-style: italic; }

/* Font style variables */
$roobert: 'Roobert', Arial, sans-serif;
$roobertBold: 'RoobertBold', Arial, sans-serif;
$roobertItalic: 'RoobertItalic', Arial, sans-serif;
$RoobertBoldItalic: 'RoobertBoldItalic', Arial, sans-serif;
$roobertSemiBold: 'RoobertSemiBold', Arial, sans-serif;
$RoobertSemiBoldItalic: 'RoobertSemiBoldItalic';

html {
  font-family: $roobert;
}

body {
  margin: 0;
  font-family: $roobert;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
