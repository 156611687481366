$white: white;
$orange: #FF5A00;
$dark-blue: #143e47;
$blue-green: #4ba59b;

body {
  overflow-x: hidden;
  min-height: 100% !important;
  height: 100vh;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#content {
  min-height: 100vh;
}

.wrapper {
  display: flex;
  align-items: stretch;
  perspective: 1500px;
  transition-delay: 0.3s;
}

@media (max-width: 768px) {
  #content.active {
    margin-right: -100px;
  }
}

.spin {
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999999;
}

.img-hover:hover {
  background: rgb(236, 236, 236);
}

.btn-cookie-consent {
  color: #fafafa;
  background-color: $orange;
  border-color: $orange;
}

.mouse-hover:hover {
  background: #B9CDBE;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.mouse-hover-dark:hover {
  background: #B9E6FF;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.logo-background {
  padding-bottom: 120px !important;
  background-image: url(https://my-environment-assets.s3.eu-central-1.amazonaws.com/en/Global+items/backgroundimage.png);
  background-size: cover;
  bottom: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.agreement-modal {
  width: 50% !important;
}

@media (max-width: 768px) {
  .agreement-modal {
    width: 100% !important;
  }
}

.padding-top-0 {
  padding-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.disable {
  pointer-events: none;
  opacity: 0.3;
}

/* Start - Overwrite Ant Design*/
.ant-input-number:focus,
.ant-input-number:hover {
  border-color: #FF5A00 !important
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: 4px solid #f5222d;
}

.ant-upload-list-item-success {
  border: 4px solid $blue-green !important;
}

.ant-modal-content {
  overflow: auto;
}

.ant-card-meta-title {
  overflow: inherit;
  white-space: break-spaces;
}

.ant-avatar {
  border-radius: 15px;
}

.ant-btn-danger {
  background-color: $orange;
  border-color: $orange;
}

.ant-btn-danger:active,
.ant-btn-danger.active {
  background-color: $orange;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  background-color: $orange;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: $orange !important;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: $dark-blue;
  font-size: 48px;
}

.ant-pagination-item-active a {
  color: $dark-blue;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: $dark-blue;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $dark-blue;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: $dark-blue;
}

.ant-pagination-item-active {
  border-color: $dark-blue;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: $orange;
  box-shadow: 0 0 0 2px $orange;
}

.ant-select-selection:hover {
  border-color: $orange;
  border-right-width: 1px !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: $dark-blue;
  border-color: $dark-blue;
}

.ant-descriptions-item-label,
.ant-descriptions-item-colon {
  width: 90px;
}

.ant-card-actions {
  align-items: flex-start !important;
}

.ant-btn:active,
.ant-btn.active {
  color: $dark-blue;
  border-color: $dark-blue;
}

.ant-switch-checked {
  background-color: $blue-green;
}

.ant-spin {
  color: black;
}

.ant-card-meta-description {
  color: $dark-blue;
}

.ant-card-actions>li>span:hover {
  color: $dark-blue;
}

.ant-card-actions>li {
  color: $dark-blue;
}

.ant-card-head {
  background: rgb(20, 62, 71);
  border-radius: 15px 15px 0px 0px;
  color: $white;
  font-size: 18px;
}

.ant-card-head-title {
  padding: 10px 0 10px 0;
  white-space: inherit;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: $white;
  border: 2px solid $white;
}

.btn:focus,
.btn.focus {
  box-shadow: 0 0 0 0.2rem $orange;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: $orange;
}

html {
  --antd-wave-shadow-color: $orange;
  min-height: 100% !important;
  height: 100%;
}

.ant-input:hover {
  border-color: $orange;
  outline: $orange auto 1px;
}

.ant-input:focus {
  border-color: $orange;
  -webkit-box-shadow: 0 0 0 2px $orange;
  box-shadow: 0 0 0 2px $orange;
  outline: $orange auto 1px;
}

.ant-input-affix-wrapper:hover {
  border-color: $orange;
  outline: $orange auto 1px;
}

.ant-input-affix-wrapper:focus {
  border-color: $orange;
  outline: $orange auto 1px;
}

a {
  color: $orange;
}

a:hover {
  color: #f26836b0;
  text-decoration: underline;
}

.ant-checkbox-inner:focus {
  outline: $orange auto 1px;
}

.ant-checkbox-checked::after {
  border: 1px solid $orange;
  content: '';
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: $orange;
}

.ant-btn-link {
  color: $orange;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $orange;
  border-color: $orange;
}

.ant-btn-primary {
  background-color: $orange;
  border-color: $orange;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #333;
  background-color: #f26836b0;
  border-color: $orange;
}

.ant-card {
  border-radius: 15px;
}

.ant-menu-horizontal>.ant-menu-item-selected>a {
  color: $white;
}

.ant-menu-horizontal>.ant-menu-item-selected>a:hover {
  color: $white;
}

.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
  color: $white;
  border-bottom: 2px solid $white;
}

.ant-menu-horizontal>.ant-menu-item>a:hover {
  color: $white;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: $blue-green;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: $white;
  border-color: $blue-green;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: $blue-green;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: $blue-green;
}

.ant-card-actions>li>span a:not(.ant-btn):hover,
.ant-card-actions>li>span>.anticon:hover {
  color: $blue-green;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $blue-green;
}

.ant-menu-item-selected>a,
.ant-menu-item-selected>a:hover {
  color: $blue-green;
}

.ant-menu-item>a:hover {
  color: $blue-green;
}

.ant-menu-submenu-selected {
  color: $blue-green;
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: $blue-green;
}

.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from($blue-green), to($blue-green));
  background: linear-gradient(to right, $blue-green, $blue-green);
}

.ant-card-meta-title {
  $white-space: unset;
}

.ant-card-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ant-card-actions>li {
  display: table;
  padding: 0px 5px;
}

.ant-card-head-title {
  $white-space: unset;
}

/* End - Overwrite Ant Design*/