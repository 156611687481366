.btn-option {
  border-radius: 0.25rem;
  cursor: pointer;
}

.btn-option-selected {
  border: 5px solid #4BA59B;
  padding: 5px;
}

.btn-option:hover,
.btn-option:focus,
.btn-option.focus,
.btn-option:active,
.btn-option.active,
.open>.dropdown-toggle.btn-option {
  color: #333;
  background-color: #B9E6FF;
  border-color: #B9E6FF;
  padding: 5px;
}