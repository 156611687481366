.login-form {
  max-width: 300px;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

#google-signin:hover {
  background-image: url(../../btn_google_signin_dark_focus_web.png);
}

#google-signin:active {
  background-image: url(../../btn_google_signin_dark_pressed_web.png);
}
