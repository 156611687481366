.btn-orange {
  color: #fafafa;
  background-color: #FF5A00;
  border-color: #FF5A00;
  margin: 5px;
  width: 50px;
}

.btn-orange:hover,
.btn-orange:focus,
.btn-orange.focus,
.btn-orange:active,
.btn-orange.active,
.open>.dropdown-toggle.btn-orange {
  color: #333;
  background-color: #f26836b0;
  border-color: #FF5A00;
}

.btn-dark-blue {
  color: #fafafa;
  background-color: #143e47;
  border-color: #143e47;
  margin: 5px;
}

.btn-dark-blue:hover,
.btn-dark-blue:focus,
.btn-dark-blue.focus,
.btn-dark-blue:active,
.btn-dark-blue.active,
.open>.dropdown-toggle.btn-dark-blue {
  color: #333;
  background-color: rgba(0, 128, 0, 0.14);
  border-color: #143e47;
}